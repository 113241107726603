import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Text Input",
  "type": "Form",
  "icon": "editLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Text Input`}</strong>{` component renders an input box for text input. It typically appears in
forms and dialogs. It combines an `}<inlineCode parentName="p">{`input`}</inlineCode>{` HTML element with corresponding `}<inlineCode parentName="p">{`label`}</inlineCode>{` and
success, failure messages, helper and more.`}</p>
    <p>{`The `}<strong parentName="p">{`Text Input`}</strong>{` component can be rendered in valid or invalid state, with or without
a placeholder and a label.`}</p>
    <hr></hr>
    <h1>{`Example`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true",
        "live": "true",
        "editor": "true"
      }}>{`() => {
  const [email, setEmail] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (email === null) {
      setError(null);
    } else if (email.includes("@")) {
      setError(null);
    } else {
      setError("Valid e-mail should include @");
    }
  }, [email]);

  return (
    <div style={{ width: 300, margin: "auto" }}>
      <TextInput
        value={email}
        placeholder="E-mail"
        state={error ? "error" : email ? "success" : undefined}
        message={error}
        onChange={(event) => setEmail(event.target.value)}
      />
    </div>
  );
};
`}</code></pre>
    <h1>{`Visual feedback`}</h1>
    <p>{`There are several ways that `}<strong parentName="p">{`Text Input`}</strong>{` can give visual feedback of the user's input.
The visual feedback differs when the `}<strong parentName="p">{`Text Input`}</strong>{` has been rendered with or without
a `}<inlineCode parentName="p">{`label`}</inlineCode>{`.`}</p>
    <h2>{`Error`}</h2>
    <p>{`Use the `}<inlineCode parentName="p">{`state="error"`}</inlineCode>{` with `}<inlineCode parentName="p">{`message`}</inlineCode>{` prop when an input has not been filled correctly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
  <TextInput defaultValue="foobar.com" placeholder="E-mail" state="error" />
  <Separator />
  <TextInput
    label="E-mail"
    defaultValue="foobar.com"
    placeholder="E-mail"
    state="error"
  />
</div>
`}</code></pre>
    <h2>{`Success`}</h2>
    <p>{`Use the `}<inlineCode parentName="p">{`state="success"`}</inlineCode>{` with `}<inlineCode parentName="p">{`message`}</inlineCode>{` prop when an input has been filled correctly.
The success state can also be used in case of a server side validation, providing the
feedback to the user once it is completed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
  <TextInput defaultValue="foo@bar.com" placeholder="E-mail" state="success" />
  <div style={{ height: "32px" }} />
  <Separator />
  <div style={{ height: "32px" }} />
  <TextInput
    label="E-mail"
    defaultValue="foo@bar.com"
    placeholder="E-mail"
    state="success"
  />
</div>
`}</code></pre>
    <h1>{`Text Alignment`}</h1>
    <p><strong parentName="p">{`Text Input`}</strong>{` accepts a `}<inlineCode parentName="p">{`textAlign`}</inlineCode>{` prop that will make the content of the input field
to be rendered on the `}<inlineCode parentName="p">{`"left"`}</inlineCode>{` ( `}<em parentName="p">{`default`}</em>{` ) or `}<inlineCode parentName="p">{`"right"`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true center=true",
        "live": "true",
        "editor": "true",
        "center": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
  <TextInput placeholder="10" icon={Icons.coinLine} textAlign="right" />
</div>
`}</code></pre>
    <h1>{`States`}</h1>
    <p><strong parentName="p">{`Text Input`}</strong>{` passes unused props to the underlying `}<inlineCode parentName="p">{`input`}</inlineCode>{` HTML element. This gives
us the possibility to use `}<inlineCode parentName="p">{`:disabled`}</inlineCode>{`, `}<inlineCode parentName="p">{`:hover`}</inlineCode>{`, `}<inlineCode parentName="p">{`:focus`}</inlineCode>{` states.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true",
        "live": "true",
        "editor": "true"
      }}>{`<div style={{ width: 300, margin: "auto" }}>
  <TextInput label="E-mail" placeholder="foo@bar.com" disabled />
</div>
`}</code></pre>
    <h1>{`Icon`}</h1>
    <p><strong parentName="p">{`Text Input`}</strong>{` also accepts an icon that will be rendered to the right, in case of no state`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true",
        "live": "true",
        "editor": "true"
      }}>{`<div style={{ width: 400, margin: "auto" }}>
  <TextInput placeholder="Search" icon={Icons.searchLine} />
</div>
`}</code></pre>
    <h1>{`Props`}</h1>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Text Input`}</strong>{` passes unused props to the underlying `}<inlineCode parentName="p">{`input`}</inlineCode>{` element.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Label rendered with the input field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"error" / "success"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of message for visual feedback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textAlign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"left" / "right"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The alignment of the text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The icon to be rendered on the right`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      